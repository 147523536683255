<template>
  <div>
    
    <div class="d-filters"
      v-if="schema.filters"
    >
      <div
        v-for="filter in schema.filters"
        :key="filter.field"
        class="d-filter"
      >
        <el-input
          v-if="filter.type == 'strict' || filter.type == 'mask'"
          :placeholder="fieldLabel(filter.field)"
          v-model="filters[filter.field]"
          clearable
        >
        </el-input>
        <el-select
          v-if="filter.type == 'select'"
          v-model="filters[filter.field]"
          filterable
          clearable
          :placeholder="fieldLabel(filter.target)"
        >
          <el-option
            v-for="item in schema.options[filter.options]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    
    <el-table
      :data="items"
      ref="table"
      stripe
      style="width: 100%"
      header-cell-class-name="d-header"
      cell-class-name="d-cell"
      :row-key="schema.id"
      row-class-name="d-row"
      @cell-click="edit"
      @sort-change="sort"
      v-loading="loading"
    >
      <el-table-column
        v-if="selector"
        width="60"
        align="center"
        fixed
        :render-header="renderSelector"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row._selected"
            @change="(v) => { select(scope.row, v) }"
          >
          </el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        label=""
        width="20"
      >
      </el-table-column>
      <el-table-column
        v-for="f in schema.fields"
        :fixed="f.fixed ? true : false"
        :key="f.name"
        :prop="f.name"
        :label="f.label"
        :width="f.width ? f.width : ''"
        :min-width="f.minWidth ? f.minWidth : ''"
        :sortable="f.sortable ? f.sortable : false"
      >
        <template slot-scope="scope">
          <el-input
            v-if="typeof editor.values[scope.row[schema.id] + ':' + scope.column.property] !== 'undefined'"
            v-model="editor.values[scope.row[schema.id] + ':' + scope.column.property]"
            @blur="save(scope.row, scope.column)"
          />
          <span
            v-else
          >{{ scope.row[scope.column.property] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="schema.rowOperations && rowOperatiomnsAmount"
        align="right"
        fixed="right"
        :width="44 * rowOperatiomnsAmount + 10 * (rowOperatiomnsAmount + 1)"
        class-name="d-controls"
      >
        <template slot-scope="scope">
          <template
            v-for="b in schema.rowOperations"
          >
            <el-popconfirm
              v-if="b.confirm"
              :key="b.name"
              :title="b.label"
              confirmButtonText="Да"
              cancelButtonText="Нет"
              hideIcon
              @onConfirm="rowOperation(b.name, scope.row)"
            >
              <el-button
                slot="reference"
                size="mini"
                :type="b.type"
                :icon="b.icon"
              >
              </el-button>
            </el-popconfirm>
            <el-button
              v-else
              :key="b.name"
              size="mini"
              :type="b.type"
              :icon="b.icon"
              @click="rowOperation(b.name, scope.row)"
            >
            </el-button>
          </template>
        </template>
      </el-table-column>

    </el-table>
    
    <div class="d-footer"
      v-if="paginator || selector"
    >
      <div class="d-controls"
        v-if="selector"
      >
        <el-popconfirm
          title="Удалить?"
          confirmButtonText="Да"
          cancelButtonText="Нет"
          hideIcon
          @onConfirm="rowOperation('delete_selected', null, { ids: getSelection() })"
        >
          <el-button
            slot="reference"
            size="mini"
            type="danger"
            icon="el-icon-delete"
          >
          </el-button>
        </el-popconfirm>
      </div>
      <div class="d-controls"
        v-else
      >
      </div>
      <el-pagination
        v-if="paginator"
        background
        style="margin-top: 20px; margin-bottom: 20px; text-align: right;"
        layout="prev, pager, next"
        :page-size="pagination.perPage"
        :total="amount"
        @current-change="pageChange"
        :current-page="pagination.page"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {

  name: 'DTable',

  props: {
    /* uid */
    uid: {
      type: String,
      default: '',
    },
    /* list */
    list: {
      type: Array,
      required: true,
    },
    /* schema */
    schema: {
      type: Object,
      required: true,
    },
    /* loading */
    loading: {
      type: Boolean,
      default: false,
    },
    /* paginator */
    paginator: {
      type: Boolean,
      default: true,
    },
    /* selector */
    selector: {
      type: Boolean,
      default: true,
    },
  },
  
  data() {
    return {
      cache: '',
      pagination: {
        perPage: 20,
        page: 1,
      },
      sorting: {
        field: '',
        reverse: false,
      },
      selector_state: 'empty',
      selection: [],
      editor: {
        fields: {},
        values: {},
      },
      filters: {},
      list_filtered: [],
    }
  },
  
  watch: {
    
    list(v) {
      //console.log('list watcher');
      this.rebuild();
    },
    
    filters: {
      handler(v){
        //console.log('filters watcher');
        this.rebuild();
        this.pagination.page = 1;
      },
      deep: true,
    },
    
  },
  
  computed: {

    amount() {
      return this.list_filtered.length;
    },

    rowOperatiomnsAmount() {
      return this.schema.rowOperations.length;
    },

    items() {
      const s = (this.pagination.page - 1) * this.pagination.perPage;
      const k = this.sorting.field;
      const temp = this.list_filtered.slice();
      if(k)
        temp.sort(
          (a, b) => {
            const result = a[k] < b[k] ? -1 : a[k] > b[k] ? 1 : 0;
            return this.sorting.reverse ? result * -1 : result;
          }
        );
      return temp.slice(s, s + this.pagination.perPage);
    },

  },

  created() {
    this.schema.fields.forEach(
      (f) => {
        if(f.editable)
          this.$set(this.editor.fields, f.name, true);
      }
    );
    if(this.schema.filters)
      this.schema.filters.forEach(
        (f) => {
          this.$set(this.filters, f.field, '');
        }
      );
    if(this.uid) {
      const k = [ '_dtable__', this.$user.id.toString(), '__', this.uid ].join('');
      const s = localStorage.getItem(k);
      if(s) {
        this.cache = s;
        const d = JSON.parse(this.cache);
        Object.keys(d.filters).forEach(
          (k) => {
            if(Object.prototype.hasOwnProperty.call(this.filters, k))
              this.filters[k] = d.filters[k];
          }
        );
        this.pagination.perPage = d.pagination.perPage;
        this.pagination.page = d.pagination.page;
      }
    }
    this.rebuild();
  },
  
  mounted() {
    if(this.uid && this.cache) {
      const d = JSON.parse(this.cache);
      this.sorting.field = d.sorting.field;
      this.sorting.reverse = d.sorting.reverse;
      this.$refs.table.sort(this.sorting.field, this.sorting.reverse ? 'descending' : 'ascending');
    }
  },
  
  destroyed() {
    if(this.uid) {
      const k = [ '_dtable__', this.$user.id.toString(), '__', this.uid ].join('');
      const d = JSON.stringify({
        filters: this.filters,
        sorting: this.sorting,
        pagination: this.pagination,
      });
      localStorage.setItem(k, d);
      //console.log(d);
    }
  },

  methods: {
    
    /* reset */
    reset() {
      if(this.schema.filters) {
        this.schema.filters.forEach(
          (f) => { this.filters[f.field] = ''; }
        );
      }
      this.rebuild();
      this.pagination.page = 1;
      this.$refs.table.clearSort();
      this.sorting.field = '';
      this.sorting.reverse = false;
    },
    
    /* rebuild */
    rebuild() {
      if(this.schema.filters) {
        const filters = this.schema.filters.filter(f => this.filters[f.field] != '');
        const ln = filters.length;
        if(ln) {
          this.list_filtered = this.list.filter(
            (item) => {
              for(var i = 0; i < ln; i++) {
                const f = filters[i];
                if(
                  (f.type == 'strict' && item[f.field].toString().toLowerCase() != this.filters[f.field].toString().toLowerCase()) ||
                  (f.type == 'mask' && item[f.field].toString().toLowerCase().indexOf(this.filters[f.field].toString().toLowerCase()) == -1) ||
                  (f.type == 'select' && item[f.field].toString().toLowerCase() != this.filters[f.field].toString().toLowerCase())
                ) {
                  return false;
                }
              }
              return true;
            }
          );
        }
        else {
          this.list_filtered = this.list.slice();
        }
      }
      else {
        this.list_filtered = this.list.slice();
      }
      this.list_filtered.forEach(
        (item) => {
          if(typeof item._selected === 'undefined')
            this.$set(item, '_selected', this.selection[item[this.schema.id]] ? true : false);
        }
      );
      this.selection = {};
      this.list_filtered.filter(item => item._selected).forEach(
        (item) => { this.selection[item.id] = true; }
      );
      this.updateSelectorState();
    },
    
    /* fieldLabel */
    fieldLabel(name) {
      const ln = this.schema.fields.length;
      var ph = '';
      for(var i = 0; i < ln; i++) {
        if(this.schema.fields[i].name == name) {
          ph = typeof this.schema.fields[i].placeholder !== 'undefined' ?
            this.schema.fields[i].placeholder : this.schema.fields[i].label;
          break;
        }
      }
      return ph;
    },
    
    /* pageChange */
    pageChange(i) {
      this.pagination.page = i;
    },
    
    /* edit */
    edit(row, column, cell) {
      if(this.editor.fields[column.property]) {
        const k = row[this.schema.id] + ':' + column.property;
        this.$set(this.editor.values, k, row[column.property]);
        this.$nextTick(() => { cell.querySelector('input').focus(); });
      }
    },
    
    /* save */
    save(row, column) {
      const k = row[this.schema.id] + ':' + column.property;
      const data = {
        operation: 'update',
        field: column.property,
        value: this.editor.values[k],
      };
      data[this.schema.id] = row[this.schema.id];
      this.$emit('operation', data);
      this.$delete(this.editor.values, k);
    },
    
    /* sort */
    sort(data) {
      if(data.prop && data.order) {
        this.sorting.field = data.prop;
        this.sorting.reverse = data.order == 'descending' ? true : false;
      }
      else {
        this.sorting.field = '';
        this.sorting.reverse = false;
      }
    },

    /* rowOperation */
    rowOperation(operation, row, args) {
      const data = {
        operation: operation,
      };
      if(row)
        data[this.schema.id] = row[this.schema.id];
      if(args)
        data.args = args;
      this.$emit('operation', data);
    },
    
    /* renderSelector */
    renderSelector(h, { column, $index }) {
      return h(
        'el-checkbox', {
          props: {
            indeterminate: this.selector_state == 'indeterminate',
            checked: this.selector_state == 'all',
          },
          on: {
            change: this.selectAll,
          },
        }
      );
    },

    /* getSelection */
    getSelection() {
      return this.list_filtered.filter(item => item._selected).map(item => item.id);
    },

    /* updateSelectorState */
    updateSelectorState() {
      var state = '';
      for(var i = 0; i < this.amount; i++) {
        if(state) {
          if(
            (state == 'all' && !this.list_filtered[i]._selected) ||
            (state == 'empty' && this.list_filtered[i]._selected)
          ) {
            state = 'indeterminate';
            break;
          }
        }
        else {
          state = this.list_filtered[i]._selected ? 'all' : 'empty';
        }
      }
      if(state != this.selector_state)
        this.selector_state = state;
    },
    
    /* selectAll */
    selectAll(v) {
      this.selector_state = v ? 'all' : 'empty';
      if(v) {
        this.list_filtered.forEach(
          (item) => { item._selected = true; this.selection[item[this.schema.id]] = true; }
        );
      }
      else {
        this.list_filtered.forEach(
          (item) => { item._selected = false; }
        );
        this.selection = [];
      }      
    },
    
    /* select */
    select(item, v) {
      this.selection[item[this.schema.id]] = v;
      this.updateSelectorState();
    },

  },

}
</script>

<style scope>
.el-table tr.d-row {
  background-color: rgba(250, 250, 250);
}

.el-table--striped .el-table__body tr.el-table__row--striped.d-row td {
  background-color: rgba(255, 255, 255);
}

.el-table-column--selection .cell {
  padding-right: 14px !important;
  padding-left: 14px !important;
  text-align: center;
}

.d-header .cell {
  font-weight: 300;
  font-size: 13px;
  white-space: nowrap;
}

.d-cell .cell {
  white-space: nowrap;
}

.cell .el-input {
  margin-left: -5px;
}

.cell .el-input__inner {
  height: 28px;
  line-height: 28px;
  padding: 0px 4px;
}

.d-filters {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: rgba(255, 255, 255);
  border-bottom: 1px solid rgb(235, 238, 245);
  flex-wrap: wrap;
}

.d-filters .d-filter {
  flex: 0 0 auto;
  margin: 10px;
}

.d-controls .cell {
  display: flex;
  justify-content: space-between;
}

.d-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-top: 1px solid rgb(235, 238, 245);
}

.d-footer .d-controls {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}
</style>
