<template>
  <div class="dt-block">
  
    <div class="dt-header">
      <div class="dt-header-main">
        <el-button
          icon="el-icon-back"
          circle
          size="small"
          @click="$router.go(-1)"
        >
        </el-button>
        <span style="margin-left: 20px;">Карты клиентов</span>
      </div>
      <div>
        <el-button
          icon="el-icon-switch-button"
          circle
          @click="reset"
        >
        </el-button>
        <el-button
          icon="el-icon-refresh"
          circle
          @click="refresh"
        >
        </el-button>
        <el-button
          icon="el-icon-plus"
          circle
          type="primary"
          @click="add"
        >
        </el-button>
      </div>
    </div>
  
    <div class="dt-data">
      <DTable
        ref="ct"
        uid="cardsList"
        :list="cards"
        :schema="schema"
        :loading="loading"
        @operation="operate"
      />
    </div>
  
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import DTable from '@/components/DTable';

export default {

  name: 'CardsList',

  components: {
    DTable,
  },
  
  data() {
    return {
      schema: schema,
      cards: [],
      loading: false,
    }
  },
  
  mounted() {
    this.get_cards();
  },

  methods: {

    /* refresh */
    refresh() {
      this.get_cards();
    },
    
    /* reset */
    reset() {
      this.$refs.ct.reset();
    },

    /* get_cards */
    get_cards() {
      this.loading = true;
      this.$request({
        path: '/new/card/list',
        data: {
            cards_ids: this.$sup.cards_ids,
        },
        call: [ this.get_cards_complete, this ],
        cache: false,
      });
    },

    /* get_cards_complete */
    get_cards_complete(status, data) {
      if(status == 200) {
        data.cards.forEach(
          (c) => {
            c.last_visit = c.last_visit ? formatDatetime(c.last_visit, 'YYYY-MM-DD hh:mm', true, false) : '';
            c.trade_su_user_id = c.trade_su_user_id ? parseInt(c.trade_su_user_id.replace(/"/g, '')).toString() : '';
          }
        );
        this.cards = data.cards;
        this.schema.options.managers.splice(0, this.schema.options.managers.length);
        Array.prototype.push.apply(this.schema.options.managers, data.managers);
      }
      this.loading = false;
    },
    
    /* operate */
    operate(data) {
      if(data.operation == 'update') {
        this.update_card(data);
      }
      else if(data.operation == 'delete') {
        this.delete_cards([data.id]);
      }
      else if(data.operation == 'edit') {
        this.$router.push({ path: '/digitender/clients/cards/view/' + data.id.toString() });
      }
      else if(data.operation == 'delete_selected') {
        this.delete_cards(data.args.ids);
      }
      else {
        console.log(data.operation);
      }
    },
    
    /* update_card */
    update_card(req) {
      this.loading = true;
      const ln = this.cards.length;
      for(var i = 0; i < ln; i++) {
        if(this.cards[i].id == req.id) {
          this.cards[i][req.field] = req.value;
          break;
        }
      }
      const data = {
        card_id: req.id,
      };
      data[req.field] = req.value;
      this.$request({
        path: '/new/card/update',
        data: data,
        call: [ this.update_card_complete, this ],
        cache: false,
      });
    },
    
    /* update_card_complete */
    update_card_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
      }
      this.refresh();
    },
    
    /* delete_cards */
    delete_cards(ids) {
      this.loading = true;
      this.$request({
        path: '/new/card/delete',
        data: {
            cards_ids: ids,
        },
        call: [ this.delete_cards_complete, this ],
        cache: false,
      });
    },

    /* delete_cards_complete */
    delete_cards_complete(status, data) {
      if(status == 200) {
        this.refresh();
      }
    },
    
    /* add */
    add() {
      this.$router.push({ path: '/digitender/clients/cards/add' });
    },
    
  },
  
};

const schema = {
  id: 'id',
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: 'integer',
      fixed: true,
      sortable: true,
      width: 120,
    },
    {
      name: 'trade_su_user_id',
      label: 'ID ( Трейд )',
      type: 'integer',
      sortable: true,
      editable: true,
      width: 120,
    },
    {
      name: 'name',
      label: 'Название',
      type: 'string',
      sortable: true,
      editable: true,
      minWidth: 240,
    },
    {
      name: 'manager_name',
      label: 'Менеджер',
      type: 'string',
      sortable: true,
      minWidth: 200,
    },
    {
      name: 'manager_p_name',
      label: 'Второй менеджер',
      type: 'string',
      sortable: true,
      minWidth: 200,
    },
    {
      name: 'last_visit',
      label: 'Последнее посещение',
      type: 'string',
      sortable: true,
      width: 190,
    },
  ],
  filters: [
    {
      field: 'trade_su_user_id',
      type: 'strict',
    },
    {
      field: 'name',
      type: 'mask',
    },
    {
      field: 'manager_id',
      type: 'select',
      target: 'manager_name',
      options: 'managers',
    },
    {
      field: 'manager_p_id',
      type: 'select',
      target: 'manager_p_name',
      options: 'managers',
    },
  ],
  rowOperations: [
    {
      name: 'edit',
      label: 'Изменить',
      icon: 'el-icon-edit',
      type: 'info',
    },
    {
      name: 'delete',
      label: 'Удалить?',
      icon: 'el-icon-delete',
      type: 'danger',
      confirm: true,
    },
  ],
  options: {
    'managers': [],
  },
};

function parseDatetime(s, fromUTC) {
  var d = null;
  const sp = s.split(/[- :]/);
  if(fromUTC) {
    d = new Date(Date.UTC(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0));
  }
  else {
    d = new Date(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0);
  }
  return d;
}

function formatDatetime(v, f, fromUTC, toUTC) {
  const fm = typeof f !== 'string' ? 'YYYY-MM-DD hh:mm:ss' : f;
  var d = typeof v === 'string' ? d = parseDatetime(v, fromUTC) : v;
  const mask = toUTC ?
    {
      'YYYY': d.getUTCFullYear(),
      'MM': ('0' + (d.getUTCMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getUTCDate()).slice(-2),
      'hh': ('0' + d.getUTCHours()).slice(-2),
      'mm': ('0' + d.getUTCMinutes()).slice(-2),
      'ss': ('0' + d.getUTCSeconds()).slice(-2),
    } :
    {
      'YYYY': d.getFullYear(),
      'MM': ('0' + (d.getMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getDate()).slice(-2),
      'hh': ('0' + d.getHours()).slice(-2),
      'mm': ('0' + d.getMinutes()).slice(-2),
      'ss': ('0' + d.getSeconds()).slice(-2),
    };
  const str = fm.replace(/(Y+|M+|D+|h+|m+|s+)/g, (s, g) => mask[g]);
  return str;
}
</script>

<style scope>
.el-notification.d-update-error {
  background-color: #F56C6C;
  border: 0;
}

.d-update-error .el-notification__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-update-error .el-notification__title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.d-update-error .el-notification__closeBtn {
  position: static;
  margin-top: 2px;
  color: rgba(255, 255, 255, .6);
}
</style>

<style>
.dt-header {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  padding: 20px;
  height: 80px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-header-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dt-data {
  padding: 0px;
}
</style>
